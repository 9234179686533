import { Button, Dialog, DialogTitle } from 'components/Elements';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { useRef } from 'react';
import { useUpdateSignature } from '../api/updateSignature';
import { PencilAltIcon } from '@heroicons/react/solid';

export function UpdateSignature({ triggerButton }) {
  const [show, setShow] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);
  const [finish, setFinish] = useState();

  const updateSignatureMutation = useUpdateSignature();

  const navigate = useNavigate();

  const clear = () => {
    sigPadRef.current.clear();
  };

  const trim = () => {
    const trimmedData = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
    setTrimmedDataURL(trimmedData);

    setFinish(true);
  };

  console.log(trimmedDataURL);

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button onClick={handleOpen}>
        {triggerButton ? triggerButton : <PencilAltIcon className="h-8" />}
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">Update Signature</DialogTitle>
          <div>
            <div className="overflow-hidden mx-auto flex flex-row items-center justify-center">
              <SignatureCanvas
                penColor="darkblue"
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: 'sigCanvas border border-gray-200 rounded-2xl shadow bg-white mb-4',
                }}
                ref={sigPadRef}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-center space-x-2">
            <Button variant="inverse" onClick={handleClose}>
              Cancel
            </Button>
            {trimmedDataURL === null ? (
              <>
                <Button variant="outline" onClick={clear}>
                  Clear
                </Button>
                <Button onClick={trim}>Confirm Signature</Button>
              </>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    const result = await updateSignatureMutation.mutateAsync({
                      data: {
                        signature: trimmedDataURL,
                      },
                    });
                    if (result) handleClose();
                  }}
                  isLoading={updateSignatureMutation.isLoading}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
