import { ContentLayout } from 'components/Layout';
import { CreateRepayment } from 'features/repayments';
import { InviteServiceProvider } from 'features/serviceProviders';
import { CreateUser } from 'features/users';
import { useAuth } from 'lib/auth';
import { Authorization, ROLES } from 'lib/authorization';
import {
  Activity,
  Archive,
  ArrowUp,
  Box,
  Cpu,
  File,
  FileText,
  Globe,
  List,
  Menu,
  Package,
  Repeat,
  Users,
} from 'react-feather';
import { CiBoxes } from 'react-icons/ci';
import { MdList, MdReport } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { RecentLoans } from '../components/RecentLoans';
import usePushNotification from 'hooks/usePushNotification';
import useSocket from 'hooks/useSocket';

export const Dashboard = () => {
  const { user } = useAuth();

  usePushNotification();
  useSocket();

  const Card = ({ title, value, icon, url }) => {
    return (
      <Link
        to={url}
        className="bg-white rounded-xl shadow-lg p-2 text-primary-200 hover:bg-primary-100 hover:text-white"
      >
        <div className="flex flex-row items-center space-x-1">
          <div className="p-2">{icon}</div>
          <div>
            <div className="text-lg font-semibold">{title}</div>
            <div className="text-xs text-gray-700">{value}</div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <ContentLayout title={`Hey 👋🏼 ${user.basicInformation?.firstName}`}>
      <div className="bg-white rounded-2xl shadow-lg p-4 mb-4">
        <div className="text-primary-200 font-bold">Quick Access</div>
        <div className="flex flex-col md:flex-row items-start md:items-center space-x-0 md:space-x-3 mt-3">
          <CreateRepayment
            triggerButton={
              <button className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white ">
                New Repayment
              </button>
            }
          />
          <Authorization forbiddenFallback={null} allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
            <InviteServiceProvider
              triggerButton={
                <InviteServiceProvider
                  triggerButton={
                    <button className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white ">
                      Invite Service Provider
                    </button>
                  }
                />
              }
            />
          </Authorization>
          <CreateUser
            triggerButton={
              <button className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white ">
                Create New Account
              </button>
            }
          />
          <Link
            to="/app/loans/check-status"
            className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white "
          >
            Check Loan Status
          </Link>
          <Link
            to="/app/loans/generate-schedule"
            className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white "
          >
            Generate Repayment Schedule
          </Link>
          <Link
            to="/app/loans/net-you-receive"
            className="bg-gray-100 p-1 rounded-full px-2 text-sm hover:bg-primary-100 hover:text-white "
          >
            Compute Net You Receive
          </Link>
        </div>
      </div>
      <div className="mb-4">
        <RecentLoans />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card
          title="Analytics"
          url="/app/analytics"
          value="Display all platform analytics"
          icon={<Activity size={20} />}
        />
        {(user.role === 'ADMIN' ||
          user.role === 'RECOVERY_OFFICER' ||
          user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'SUPER_ADMIN') && (
          <Card
            title="Contracts"
            url="/app/contracts"
            value="Display all loans and handle new loan requests"
            icon={<File size={20} />}
          />
        )}
        {(user.role === 'ADMIN' ||
          user.role === 'RECOVERY_OFFICER' ||
          user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'SUPER_ADMIN') && (
          <Card
            title="Loans Center"
            url="/app/loans"
            value="Display all loans and handle new loan requests"
            icon={<FileText size={20} />}
          />
        )}
        {/* {user.role === ('SUPER_ADMIN' || 'ADMIN') && (
        )} */}
        <Card
          title="Product Development"
          url="/app/products"
          value="Display and Manage Products listings"
          icon={<Box size={20} />}
        />
        {user.role === 'MARKETING_OFFICER' ||
          (user.role === 'SUPER_ADMIN' && (
            <Card
              title="Asset Management"
              url="/app/assets"
              value="Manage collateral assets."
              icon={<Archive size={20} />}
            />
          ))}

        {(user.role === 'SUPER_ADMIN' || user.role === 'ADMIN') && (
          <Card
            title="User Accounts"
            url="/app/accounts"
            value="Manage platform user accounts & access"
            icon={<Users size={20} />}
          />
        )}
        {(user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN') && (
          <Card
            title="Repayments"
            url="/app/repayments"
            value="Display all complete & pending payments"
            icon={<Menu size={20} />}
          />
        )}
        {(user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN') && (
          <Card
            title="Transactions"
            url="/app/transactions"
            value="Display all transactions made by users"
            icon={<Repeat size={20} />}
          />
        )}
        {user.role === 'ADMIN' ||
          (user.role === 'SUPER_ADMIN' && (
            <Card
              title="Reports"
              url="/app/reports"
              value="Displays all loan and system reports "
              icon={<List size={20} />}
            />
          ))}
        {user.role === 'ADMIN' ||
          (user.role === 'SUPER_ADMIN' && (
            <Card
              title="Companies"
              url="/app/companies"
              value="Manage company accounts & loans."
              icon={<Globe size={20} />}
            />
          ))}
        {(user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN' ||
          user.role === 'RECOVERY_OFFICER') && (
          <Card
            title="Due-Diligence"
            url="/app/due-deligences"
            value="Manage collateral assets in due-deligence."
            icon={<Cpu size={20} />}
          />
        )}
        {user.role === 'ADMIN' ||
          (user.role === 'SUPER_ADMIN' && (
            <Card
              title="Service Providers"
              url="/app/service-providers"
              value="Manage service provider accounts."
              icon={<Package size={20} />}
            />
          ))}
        {(user.role === 'COLLECTIONS_OFFICER' ||
          user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN' ||
          user.role === 'MARKETING_OFFICER') && (
          <Card
            title="Loan Tips"
            url="/app/tips"
            value="Manage loan tips."
            icon={<MdList size={20} />}
          />
        )}
        {(user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN' ||
          user.role === 'MARKETING_OFFICER') && (
          <Card
            title="Blog Posts"
            url="/app/posts"
            value="Create & manage blog posts."
            icon={<MdList size={20} />}
          />
        )}
        {(user.role === 'LOAN_OFFICER' ||
          user.role === 'ADMIN' ||
          user.role === 'SUPER_ADMIN' ||
          user.role === 'MARKETING_OFFICER') && (
          <Card
            title="FAQs"
            url="/app/faqs"
            value="Frequently asked questions."
            icon={<MdList size={20} />}
          />
        )}
        {user.role === 'SUPER_ADMIN' && (
          <Card
            title="Officers"
            url="/app/officers"
            value="Manage officers personal accounts."
            icon={<Users size={20} />}
          />
        )}
        {user.role === 'SUPER_ADMIN' && (
          <Card
            title="Directors"
            url="/app/directors"
            value="Manage directors profiles."
            icon={<Users size={20} />}
          />
        )}
        {(user.role === 'SUPER_ADMIN' || user.role === 'DEVELOPER') && (
          <Card
            title="Finbot"
            url="/app/finbot"
            value="Manage finbot whatsapp bot."
            icon={<CiBoxes size={20} />}
          />
        )}
      </div>
    </ContentLayout>
  );
};
