import { Button, Dialog, DialogTitle } from 'components/Elements';
import React, { useEffect, useState } from 'react';
import { Form, InputField } from 'components/Form';
import { useUpdateVehicleDetail } from '../api/udpateVehicleDetail';

export function EditCollateralDetail({ id, initialData }) {
  const [show, setShow] = React.useState(false);
  const [formValues, setFormValues] = useState(initialData || {});

  const updateVehicleMutation = useUpdateVehicleDetail();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  useEffect(() => {
    if (initialData) {
      setFormValues(initialData);
    }
    console.log(initialData);
  }, [initialData]);

  return (
    <div>
      <Button onClick={handleOpen} className="flex items-center">
        Edit
      </Button>

      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">
            Update Collateral Detail
          </DialogTitle>
          <div className="mb-4">
            <Form
              id="update-loan-amount"
              onSubmit={async (values) => {
                await updateVehicleMutation.mutateAsync({
                  data: { ...values },
                  id: id,
                });
                handleClose();
              }}
              options={{
                defaultValues: initialData,
              }}
            >
              {({ formState, register }) => (
                <>
                  <InputField
                    label="Make/Brand"
                    error={formState.errors['brand']}
                    registration={register('brand')}
                  />
                  <InputField
                    label="Number Plate"
                    error={formState.errors['no_plate']}
                    registration={register('no_plate')}
                  />
                  <InputField
                    label="Engine No."
                    error={formState.errors['engine_no']}
                    registration={register('engine_no')}
                  />
                  <InputField
                    label="Chasis No."
                    error={formState.errors['chasis_no']}
                    registration={register('chasis_no')}
                  />
                  <InputField
                    label="Mileage"
                    error={formState.errors['mileage']}
                    registration={register('mileage')}
                  />
                  <InputField
                    label="Model"
                    error={formState.errors['model']}
                    registration={register('model')}
                  />
                  <InputField
                    label="Color"
                    error={formState.errors['color']}
                    registration={register('color')}
                  />

                  <div className="flex flex-row justify-center items-center space-x-2">
                    <Button onClick={handleClose} variant="outline">
                      Cancel
                    </Button>
                    <Button type="submit" isLoading={updateVehicleMutation.isLoading}>
                      Update
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
