import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useLoan } from '../api/getLoan';
import { useUpdateLoanDetails } from '../api/updateLoanDetails';

export const UpdateLoanDetails = ({ id }) => {
  const loanQuery = useLoan({ id });
  const updateLoanMutation = useUpdateLoanDetails();

  if (updateLoanMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.LOAN_OFFICER, ROLES.SUPER_ADMIN]}>
      <FormDrawer
        isDone={updateLoanMutation.isSuccess}
        size="sm"
        triggerButton={
          <Button variant="outline" startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Edit Loan Requirements
          </Button>
        }
        title="Update Loan Details"
        submitButton={
          <Button
            form="update-loan-details"
            type="submit"
            size="sm"
            isLoading={updateLoanMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-loan-details"
          onSubmit={async (values) => {
            console.log(values);
            await updateLoanMutation.mutateAsync({ data: values, id });
          }}
          options={{
            defaultValues: {
              loanRequired: loanQuery.data?.amount?.loanRequired,
              term: loanQuery.data?.term,
              interestRate: loanQuery?.data?.interestRate,
              monthlyPayment: loanQuery?.data?.monthlyPayment,
              totalPayable: loanQuery?.data?.totalPayable,
              principalPayable: loanQuery?.data?.principalPayable,
              interestCharged: loanQuery?.data?.interestCharged,
            },
          }}
        >
          {({ formState, register }) => (
            <>
              <InputField
                label="Loan Amount"
                type="number"
                error={formState.errors['loanRequired']}
                registration={register('loanRequired')}
              />
              <InputField
                label="Term"
                type="number"
                error={formState.errors['term']}
                registration={register('term')}
              />
              <InputField
                label="Interest"
                type="number"
                error={formState.errors['interestRate']}
                registration={register('interestRate')}
              />
              <InputField
                label="Monthly Payment"
                type="number"
                error={formState.errors['monthlyPayment']}
                registration={register('monthlyPayment')}
              />
              <InputField
                label="Total Payable"
                type="number"
                error={formState.errors['totalPayable']}
                registration={register('totalPayable')}
              />
              <InputField
                label="Principal Payable"
                type="number"
                error={formState.errors['principalPayable']}
                registration={register('principalPayable')}
              />
              <InputField
                label="Interest Charged"
                type="number"
                error={formState.errors['interestCharged']}
                registration={register('interestCharged')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

UpdateLoanDetails.propTypes = {
  id: PropTypes.string.isRequired,
};
