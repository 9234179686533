import { Button, Dialog, DialogDescription, DialogTitle } from 'components/Elements';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye } from 'react-feather';
import { UPLOADS_URL } from 'config';

export function NINPreview({ title, location }) {
  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className="bg-white border border-gray-200 rounded-xl shadow p-2 w-full"
      >
        <div className="text-gray-700 flex flex-row items-center space-x-2 justify-center font-semibold text-md">
          <Eye className="inline-block" />
          <span>Preview {title}</span>
        </div>
      </button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <DialogTitle className="text-xl text-center font-bold mb-4">{title}</DialogTitle>
          <div>
            <img
              src={`${UPLOADS_URL}/id_signature_images/${location}`}
              alt={title}
              className="w-full "
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
