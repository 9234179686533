import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const updateLoanDetails = ({ id, data }) => {
  return axios.patch(`/loans/${id}/loan-update`, data);
};

export const useUpdateLoanDetails = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {},
    onError: (_, __, context) => {},
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Loan Updated',
      });
    },
    ...config,
    mutationFn: updateLoanDetails,
  });
};
