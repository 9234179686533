import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const approveDueDiligence = ({ data, id }) => {
  return axios.post(`/v2/loans/${id}/credit_committee`, data);
};

export const useApproveDueDiligence = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (submittingValuation) => {
      await queryClient.cancelQueries(['loan', submittingValuation?.id]);

      const previousValuation = queryClient.getQueryData(['loan', submittingValuation?.id]);

      queryClient.setQueryData(['loan', submittingValuation?.id], {
        ...previousValuation,
        ...submittingValuation.data,
        id: submittingValuation.id,
      });

      return { previousValuation };
    },
    onError: (_, __, context) => {
      // Handle error if needed
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['loan', data._id]);
      addNotification({
        type: 'success',
        title: 'Valuation Submitted',
      });
    },
    ...config,
    mutationFn: approveDueDiligence,
  });
};
