import { SOCKET_SERVER } from 'config';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const useSocket = (userId) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (userId) {
      // Connect to the socket server immediately when the userId is available
      const newSocket = io(SOCKET_SERVER, {
        query: { userId }, // Send the userId as a query parameter
        transports: ['websocket', 'polling'],
        path: '/socket',
      });

      newSocket.on('connect', () => {
        setIsConnected(true);
        console.log(`Connected to socket server with id: ${newSocket.id}`);
      });

      newSocket.on('disconnect', () => {
        setIsConnected(false);
        console.log('Disconnected from socket server');
      });

      // Listen for incoming messages from the server
      newSocket.on('messages', (newMessages) => {
        setMessages(newMessages);
      });

      // Handle errors
      newSocket.on('error', (error) => {
        console.error('Socket error:', error);
      });

      // Set the socket in the state
      setSocket(newSocket);

      // Cleanup function to disconnect the socket when the component is unmounted
      return () => {
        newSocket.disconnect();
        console.log('Socket disconnected');
      };
    }
  }, [userId]); // Re-run effect when userId changes

  // Function to send messages to the server
  const sendMessage = (chatId, message) => {
    if (socket) {
      socket.emit('getMessages', chatId);
      socket.emit('sendMessage', message);
    }
  };

  // Function to update user status (like online/offline)
  const updateStatus = () => {
    if (socket) {
      socket.emit('updateStatus', userId);
    }
  };

  return {
    socket,
    isConnected,
    messages,
    sendMessage,
    updateStatus,
  };
};

export default useSocket;
