import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const addImageToCollateral = ({ id, data }) => {
  return axios.post(`/v2/collaterals/${id}/add-image`, data);
};

export const useAddImageToCollateral = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingCollateral) => {
      await queryClient.cancelQueries(['collateral', updatingCollateral?.id]);

      const previousCollateral = queryClient.getQueryData[('collateral', updatingCollateral?.id)];

      queryClient.setQueryData(['collateral', updatingCollateral?.id], {
        ...previousCollateral,
        ...updatingCollateral.data,
        id: updatingCollateral.id,
      });

      return { previousCollateral };
    },
    onError: (_, __, context) => {
      if (context?.previousCollateral) {
        queryClient.setQueryData(
          ['collateral', context.previousCollateral.id],
          context.previousCollateral
        );
      }
      addNotification({
        type: 'error',
        title: 'Image Upload Failed',
        message: 'Failed to add image to collateral.',
      });
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['collateral', data.collateral._id]);
      addNotification({
        type: 'success',
        title: 'Image Uploaded',
        message: 'Image successfully added to collateral.',
      });
    },
    ...config,
    mutationFn: addImageToCollateral,
  });
};
