import React, { useState } from 'react';
import { Button, Spinner } from 'components/Elements';
import { Form, InputField, FormDrawer } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import { useLoan } from '../api/getLoan';
import { useApproveDueDiligence } from '../api/approveDueDiligence';

export const ApproveDueDiligence = ({ id }) => {
  const navigate = useNavigate();

  const submitValuationMutation = useApproveDueDiligence();

  const loanQuery = useLoan({ id });

  const [valuation_opinion, setValuationOpinion] = useState('');
  const [buyers_opinion, setBuyersOpinion] = useState('');
  const [forth_opinion, setForthOpinion] = useState('');
  const [efl_opinion, setEflOpinion] = useState('');
  const [collateral_market_value, setCollateralMarketValue] = useState(0);
  const [collateral_forced_sale_value, setCollateralForcedSaleValue] = useState(0);
  const [approved_loan_max_value, setApprovedLoanMaxValue] = useState(0);

  const calculateValues = () => {
    const marketValue =
      (parseInt(valuation_opinion || 0) +
        parseInt(buyers_opinion || 0) +
        parseInt(forth_opinion || 0) +
        parseInt(efl_opinion || 0)) /
      4;
    const forcedSaleValue = 0.6 * marketValue;
    const maxLoanValue = 0.5 * forcedSaleValue;

    setCollateralMarketValue(marketValue);
    setCollateralForcedSaleValue(forcedSaleValue);
    setApprovedLoanMaxValue(maxLoanValue);
  };

  if (loanQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="sm" />
      </div>
    );
  }

  if (!loanQuery.data) return null;

  return (
    <FormDrawer
      isDone={submitValuationMutation.isSuccess}
      size="sm"
      triggerButton={
        <button className="w-full bg-gray-800 hover:bg-gray-900 hover:shadow-lg text-white rounded-2xl text-start mb-3 p-3 px-5">
          <span className="text-md">Qualify Loan</span>
          <br />
          <span className="text-xs">Provide valuation & qualify loan.</span>
        </button>
      }
      title="Qualify Loan"
      submitButton={
        <Button
          form="submit-valuation"
          type="submit"
          size="sm"
          isLoading={submitValuationMutation.isLoading}
        >
          Submit
        </Button>
      }
    >
      <Form
        id="submit-valuation"
        onSubmit={async () => {
          await submitValuationMutation.mutateAsync({
            data: {
              valuation_opinion,
              buyers_opinion,
              forth_opinion,
              efl_opinion,
              collateral_market_value,
              collateral_forced_sale_value,
              approved_loan_max_value,
            },
            id: loanQuery.data?._id,
          });
          navigate(`/app/loans/${loanQuery.data?._id}`);
        }}
      >
        {({ formState }) => (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <InputField
                label="EFL Opinion (UGX)"
                error={formState.errors['efl_opinion']}
                onChange={(e) => {
                  setEflOpinion(e.target.value);
                  // setCollateralMarketValue(
                  //   (parseInt(valuation_opinion) +
                  //     (parseInt(forth_opinion) || 0) +
                  //     (parseInt(e.target.value) || 0) +
                  //     (parseInt(buyers_opinion) || 0)) /
                  //     4
                  // );
                  setCollateralForcedSaleValue(0.6 * collateral_market_value);
                  setApprovedLoanMaxValue(0.5 * collateral_forced_sale_value);
                }}
              />
              <InputField
                label="Collateral Market Value (UGX)"
                error={formState.errors['collateral_market_value']}
                value={collateral_market_value}
                onChange={(e) => setCollateralMarketValue(e.target.value)}
                caption="Value of collateral market will be computed as the average of the valuation opinion, buyers opinion, forth opinion and EFL opinion if not provided."
              />

              <InputField
                label="Approved Loan Max Value (UGX)"
                error={formState.errors['approved_loan_max_value']}
                onChange={(e) => setApprovedLoanMaxValue(e.target.value)}
                value={approved_loan_max_value}
                caption="Value of approved loan max will be computed as 50% of the collateral forced sale value if not provided."
              />
            </div>
          </>
        )}
      </Form>
    </FormDrawer>
  );
};
