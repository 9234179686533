import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import { useMutation } from 'react-query';
import { subscribePushNotifications } from 'features/auth'; // Assuming you have a backend call here
import { useAuth } from 'lib/auth';

const usePushNotification = () => {
  const { data: user, refetch } = useAuth(); // Fetch user data using useAuth
  const [isSubscribed, setIsSubscribed] = useState(false);

  // Mutation to update user push subscriptions
  const { mutate: updateUserSubscriptions } = useMutation(async (subscriptionData) => {
    // Update the push subscription in your backend
    await subscribePushNotifications(subscriptionData); // Pass subscription data directly, no need to stringify
    // Refetch user data to reflect the new subscription
    refetch();
  });

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Checking subscription status...');
        try {
          const registration = await navigator.serviceWorker.ready;

          // Get existing subscription
          const existingSubscription = await registration.pushManager.getSubscription();
          if (existingSubscription) {
            setIsSubscribed(true);
            console.log('Already subscribed to push notifications');
          } else {
            setIsSubscribed(false);
            console.log('Not subscribed yet');
          }
        } catch (error) {
          console.error('Error checking subscription status:', error);
        }
      }
    };

    if (user) {
      checkSubscriptionStatus();
    }
  }, [user]);

  const promptToSubscribe = async () => {
    // Ask the user for permission using Swal
    const result = await Swal.fire({
      title: 'Do you want to receive push notifications?',
      text: 'Stay updated with your loan progress, contracts, and more.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, subscribe!',
      cancelButtonText: 'No, thanks',
    });

    if (result.isConfirmed) {
      // Proceed to subscribe the user
      try {
        const registration = await navigator.serviceWorker.ready;

        // Check if the user is already subscribed
        const existingSubscription = await registration.pushManager.getSubscription();
        if (existingSubscription) {
          setIsSubscribed(true);
          Swal.fire(
            'Already subscribed!',
            'You are already subscribed to push notifications.',
            'info'
          );
          return;
        }

        // Subscribe the user
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BDSYV-b56ytaFdbJ0b8nqL-yy6aktMiO4uXOHNugdOhkHFLJy3WNW2qRq66DaZrr4slP8o7kWNzK3LjJ5w8BHDM', // Replace with your actual VAPID key
        });

        // Save the subscription details to the user's profile
        const subscriptionData = {
          endpoint: subscription.endpoint,
          keys: {
            p256dh: subscription.getKey('p256dh'),
            auth: subscription.getKey('auth'),
          },
        };

        // Update user subscription details (using mutation)
        updateUserSubscriptions(subscriptionData);

        setIsSubscribed(true);
        Swal.fire('Subscribed!', 'You will now receive push notifications.', 'success');
      } catch (error) {
        console.error('Error subscribing user to push notifications:', error);
        Swal.fire(
          'Subscription Failed',
          'An error occurred while subscribing. Please try again.',
          'error'
        );
      }
    }
  };

  // Prompt to subscribe if the user hasn't already subscribed
  useEffect(() => {
    if (!isSubscribed && user && user.pushSubscriptions?.length === 0) {
      promptToSubscribe();
    }
  }, [isSubscribed, user]);

  return isSubscribed;
};

export default usePushNotification;
