const storagePrefix = 'msw-db';

const storage = {
  checkPushSubscription: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}_push_subscription`));
  },
  subscribePushNotifications: (subscriptionStatus) => {
    window.localStorage.setItem(
      `${storagePrefix}_push_subscription`,
      JSON.stringify(subscriptionStatus)
    );
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
};

export default storage;
