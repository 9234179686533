import { Button, Spinner } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { useRepayment } from '../api/getRepayment';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Printer } from 'react-feather';
import { formatDate } from 'utils/format';
import { UPLOADS_URL } from 'config';
import { EvidenceOfPayment } from '../components/EvidenceOfPayment';
import { ApproveRepayment } from '../components/ApproveRepayment';
import { formatText } from 'utils/formatText';
import { formatDuration } from 'utils/formatDuration';
import { ReallocateRepayment } from '../components/ReallocateRepayment';

export const Repayment = () => {
  const { id } = useParams();
  const repaymentQuery = useRepayment({ id });

  const repaymentToPrint = useRef();

  const handlePrint = useReactToPrint({
    content: () => repaymentToPrint.current,
    documentTitle: `Repayment_${id}`,
  });

  const routes = [
    {
      name: 'Repayments',
      path: '/app/repayments',
    },
    {
      name: 'Repayment',
      path: '/app/repayments/:id',
    },
  ];

  const navigate = useNavigate();

  if (repaymentQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!repaymentQuery.data) return null;

  return (
    <>
      <Head title="Repayment" />
      <ContentLayout
        title="Repayment"
        end={
          <div className="flex flex-row items-center space-x-2">
            {repaymentQuery.data?.status !== 'CLEARED' && <ApproveRepayment />}
            <Button size="sm" startIcon={<Printer size={14} />} onClick={handlePrint}>
              Print
            </Button>
            {repaymentQuery.data.status !== 'CLEARED' && <ReallocateRepayment id={id} />}

            <EvidenceOfPayment url={repaymentQuery.data?.paymentDoc} />
          </div>
        }
      >
        <Breadcrumbs routes={routes} />
        <div className="mt-6">
          <div className="bg-white rounded-2xl shadow">
            <div ref={repaymentToPrint} className="">
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Status</div>
                <div className="">{formatText(repaymentQuery.data?.status)}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Amount Expected</div>
                <div className="">UGX {repaymentQuery.data?.amountExpected?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Scheduled Installment</div>
                <div className="">{repaymentQuery.data?.scheduledInstallment}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Date Expected</div>
                <div className="">{formatDate(repaymentQuery.data?.dateExpected)}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Amount Received</div>
                <div className="">UGX {repaymentQuery.data?.amountReceived?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Actual Installment</div>
                <div className="">{repaymentQuery.data?.actualInstallment}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Date Received</div>
                <div className="">{formatDate(repaymentQuery.data?.dateReceived)}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Late Days</div>
                <div className="">{repaymentQuery.data?.lateDays}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Late Fees Due</div>
                <div className="">UGX {repaymentQuery.data?.lateFeesDue?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Late Fees Paid</div>
                <div className="">UGX {repaymentQuery.data?.lateFeesPaid?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Late Fees Balance</div>
                <div className="">UGX {repaymentQuery.data?.lateFeesBalance?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Interest Due</div>
                <div className="">UGX {repaymentQuery.data?.interestDue?.toLocaleString()}</div>
              </div>
              <hr />
              {/* <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Interest Charged</div>
                <div className="">UGX {repaymentQuery.data?.interestCharged?.toLocaleString()}</div>
              </div>
              <hr /> */}
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Interest Paid</div>
                <div className="">UGX {repaymentQuery.data?.interestPaid?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Interest Balance</div>
                <div className="">UGX {repaymentQuery.data?.interestBalance?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Principal Due</div>
                <div className="">UGX {repaymentQuery.data?.principalDue?.toLocaleString()}</div>
              </div>
              <hr />
              {/* <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Principal Lent</div>
                <div className="">UGX {repaymentQuery.data?.principalLent?.toLocaleString()}</div>
              </div>
              <hr /> */}
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Principal Paid</div>
                <div className="">UGX {repaymentQuery.data?.principalPaid?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Principal Balance</div>
                <div className="">
                  UGX {repaymentQuery.data?.principalBalance?.toLocaleString()}
                </div>
              </div>
              <hr />

              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Penalties Due</div>
                <div className="">UGX {repaymentQuery.data?.penaltiesDue?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Penalties Balance</div>
                <div className="">
                  UGX {repaymentQuery.data?.penaltiesBalance?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Penalties Paid</div>
                <div className="">UGX {repaymentQuery.data?.penaltiesPaid?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Total Outstanding Balance</div>
                <div className="">
                  UGX {repaymentQuery.data?.totalOutstandingBalance?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">EFL Commission</div>
                <div className="">UGX {repaymentQuery.data?.eflCommission?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Lenders Net Earnings</div>
                <div className="">
                  UGX {repaymentQuery.data?.lendersNetEarnings?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Lenders Net Credited</div>
                <div className="">
                  UGX {repaymentQuery.data?.lendersNetCredited?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Service Fee</div>
                <div className="">UGX {repaymentQuery.data?.serviceFee?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Monthly Interest</div>
                <div className="">{repaymentQuery.data?.monthlyInterest} %</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Net monthly Interest </div>
                <div className="">{repaymentQuery.data?.netMonthlyInterest} %</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Arrears</div>
                <div className="">UGX {repaymentQuery.data?.arrears?.toLocaleString()}</div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Principal In Arrears</div>
                <div className="">
                  UGX {repaymentQuery.data?.principalInArrears?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Real Portfolio At Risk</div>
                <div className="">
                  UGX {repaymentQuery.data?.realPortfolioAtRisk?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Lifetime Portfolio At Risk</div>
                <div className="">
                  UGX {repaymentQuery.data?.lifeTimePortfolioAtRisk?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Early Repayment Fee</div>
                <div className="">
                  UGX {repaymentQuery.data?.earlyRepaymentFee?.toLocaleString()}
                </div>
              </div>
              <hr />
              <div className="grid grid-cols-2 gap-6 px-12 py-2 hover:bg-gray-100">
                <div className="">Loan Expires In</div>
                <div className="">{formatDuration(repaymentQuery.data?.loanExpiresIn)}</div>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );
};
