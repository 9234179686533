import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import PropTypes from 'prop-types';
import { useInitiateContract } from '../api/initiateContract';

export const InitiateContract = ({ id }) => {
  const initiateContractMutation = useInitiateContract();

  if (initiateContractMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN, ROLES.SUPER_ADMIN]}>
      <ConfirmationDialog
        icon="info"
        title="Generate Contract"
        body="This action will generate a contract for this loan and send it to the borrower. Are you sure you want to proceed?"
        triggerButton={
          <button className="w-full bg-gray-800 hover:bg-gray-900 hover:shadow-lg text-white rounded-2xl text-start mb-3 p-3 px-5">
            <span className="text-md">Generate Contract</span>
            <br />
            <span className="text-xs">Generate a contract for the loan.</span>
          </button>
        }
        confirmButton={
          <Button
            isLoading={initiateContractMutation.isLoading}
            type="button"
            onClick={async () => await initiateContractMutation.mutateAsync({ id: id })}
          >
            Initiate
          </Button>
        }
      />
    </Authorization>
  );
};

InitiateContract.propTypes = {
  id: PropTypes.string.isRequired,
};
