import React, { useState } from 'react';
import { Button, FullScreenSpinner, Spinner } from 'components/Elements';
import { Form, FormDrawer } from 'components/Form';
import { Image, UploadCloud } from 'react-feather';
import { useAsset } from '../api/getAsset';
import { useAddImageToCollateral } from '../api/addImage';

export const AddImage = ({ id }) => {
  const [image, setImage] = useState(null);

  const addImageMutation = useAddImageToCollateral();
  const collateralQuery = useAsset({ id });

  if (collateralQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (addImageMutation.isLoading) return <FullScreenSpinner />;

  return (
    <FormDrawer
      isDone={addImageMutation.isSuccess}
      triggerButton={
        <button className="bg-gray-100 hover:bg-primary-100 hover:text-white p-1 px-2 text-sm flex flex-row items-center space-x-2">
          <span>Add Image</span>
        </button>
      }
      title="Add Image to Collateral"
      submitButton={
        <Button form="add-image" type="submit" size="sm" isLoading={addImageMutation.isLoading}>
          Submit
        </Button>
      }
    >
      <Form
        id="add-image"
        onSubmit={async () => {
          const formData = new FormData();
          formData.append('image', image);
          await addImageMutation.mutateAsync({
            id: collateralQuery.data._id,
            data: formData,
          });
        }}
      >
        {() => (
          <div className="mb-4">
            <div className="text-sm mb-3">Upload a clear image of the collateral.</div>
            <div className="flex flex-row items-center justify-between">
              <div>
                <input
                  type="file"
                  className="hidden"
                  id="collateral-image"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                />
                <label
                  htmlFor="collateral-image"
                  className="bg-white flex flex-row items-center space-x-2 border border-gray-300 rounded-lg p-2 cursor-pointer"
                >
                  <UploadCloud />
                  <span className="ml-2">Upload Image</span>
                </label>
              </div>
            </div>
          </div>
        )}
      </Form>
    </FormDrawer>
  );
};
