import { Button, Spinner } from 'components/Elements';
import { Notifications } from 'components/Notifications/Notifications';
import { AuthProvider } from 'lib/auth';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
// import { useIdleTimer } from 'react-idle-timer';
import { SubmitComplaint } from 'features/support';
// In your component or a utility file
import { getToken } from 'firebase/messaging';
import { messaging } from 'lib/firebase';
import { useEffect } from 'react';
import { axios } from 'lib/axios';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Sorry, something went wrong </h2>
      <Button
        className="mt-4"
        onClick={() => {
          localStorage.clear();
          window.location.assign(window.location.origin);
        }}
      >
        Refresh
      </Button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>
                <SubmitComplaint />
                {children}
              </Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
